<template>
  <div class="navigation">
    <div class="navigation-left">
      <div>山西农村供水</div>
      <div>信息化技术服务平台</div>
    </div>
    <a-button
      @click="
        () => {
          this.$router.go(-1);
        }
      "
      style="position:fixed;right:50px;top:50px"
      ><a-icon type="logout" size="large" />退出登陆</a-button
    >
    <div class="navigation-right">
      <div class="first" @click="goToHy(2)">行业管理</div>
      <div class="second">
        <div class="second-l" @click="goToHy(3)">水质管理</div>

        <div class="second-l" @click="goToHy(4)">数据填报</div>
        <div class="second-r">水厂监控</div>
      </div>
      <div class="third">远程培训</div>
    </div>
  </div>
</template>

<script type="text/javascript">
export default {
  name: "navigation",
  data() {
    return {
      routers: [], //总路由
      useRouter: [], //显示侧导航路由
      menus: []
    };
  },
  created() {
    this.routers = this.$router.options.routes;
    this.menus = JSON.parse(localStorage.getItem("menus"));
  },
  methods: {
    goToHy(index) {
      let nav = "";

      for (let i in this.menus) {
        if (this.menus[i].menu_name == "行业管理" && index == 2) {
          nav = i;
        }
        if (this.menus[i].menu_name == "水质管理" && index == 3) {
          nav = i;
        }
        if (this.menus[i].menu_name == "数据填报" && index == 4) {
          nav = i;
        }
      }

      if (nav != "") {
        this.useRouter = this.menus[nav];
        this.$store.commit("setRouters", this.useRouter);
        localStorage.setItem("nav", nav);
        let routers = JSON.stringify(this.useRouter);
        localStorage.setItem("routers", routers);
        let path = this.useRouter.children[0].component
          ? this.useRouter.children[0].component
          : this.useRouter.children[0].children[0].component;
        if (
          this.useRouter.children.length > 0 &&
          this.useRouter.children[0].component
        ) {
          this.$store.commit("setNavNum", this.useRouter.children[0].dbId);
        } else {
          this.$store.commit(
            "setNavNum",
            this.useRouter.children[0].children[0].dbId
          );
        }
        this.$router.push({ path: path });
      } else {
        this.$message.error("暂无权限访问，请联系管理员");
      }
    }
  }
};
</script>

<style scoped>
.navigation {
  width: 100%;
  height: 100vh;
  background: #ffffff;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  justify-content: space-between;
}
.navigation-left {
  width: 42%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(0deg, #c2dea4 0%, #bfddab 100%);
}
.navigation-right {
  width: 56%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.navigation-right .first {
  cursor: pointer;
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(90deg, #d5c0f9 0%, #b2c1fc 100%);
}
.navigation-right .second {
  width: 100%;
  height: 44%;
  display: flex;
  justify-content: space-between;
}
.second-l {
  cursor: pointer;
  width: 48.5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(-90deg, #eaaa84 0%, #e87c93 100%);
}
.second-r {
  cursor: pointer;
  width: 48.5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(90deg, #9cd7db 0%, #a3e9cf 100%);
}
.navigation-right .third {
  cursor: pointer;
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(90deg, #71c6ed 0%, #3e79d7 100%);
}
</style>
